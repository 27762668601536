"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chronicUrticaria = void 0;
const cognito_1 = require("../../../model/cognito");
const legal_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/default-form-data-guards/legal-data-guard");
const social_demographics_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/default-form-data-guards/social-demographics-data-guard");
const updated_legal_policy_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/default-form-data-guards/updated-legal-policy-data-guard");
const form_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/form-data-guard");
const profile_inputs_1 = require("../../../model/data-collection/profile/profile-inputs");
const diseases_1 = require("../../../model/diseases");
const languages_1 = require("../../../model/languages");
const lock_1 = require("../../../model/lock/lock");
const user_feedbacks_1 = require("../../../model/user-feedback/user-feedbacks");
const deployment_environments_1 = require("../../deployment-environments");
exports.chronicUrticaria = {
    diseaseType: diseases_1.DiseaseType.DISEASE,
    disease: diseases_1.Disease.CHRONIC_URTICARIA,
    organisations: [],
    theme: {
        daisyTheme: {
            primary: "#3ca66a",
            "primary-focus": "#34915d",
            "primary-content": "#FFFFFF",
            secondary: "#88a9e3",
            "secondary-focus": "#7895c8",
            "secondary-content": "#FFFFFF",
            accent: "#3459A3",
            "accent-focus": "#2D4D8C",
            "accent-content": "#FFFFFF",
            neutral: "#424242",
            "neutral-focus": "#333333",
            "neutral-content": "#FFFFFF",
            "base-100": "#ffffff",
            "base-200": "#f0f0f0",
            "base-300": "#e0e0e0",
            "base-content": "#1f1f1f",
            info: "#25dbf1",
            "info-content": "#082f34",
            success: "#30ca6b",
            "success-content": "#14522a",
            warning: "#dec762",
            "warning-content": "#524b2b",
            error: "#ff4d4d",
            "error-content": "#ffffff",
            "--btn-text-case": "none",
        },
        extendedTheme: {
            colors: {
                mamaAskUserChatBubble: "#43d178",
                mamaAnswerUserChatBubble: "#1f3560",
            },
        },
    },
    supportedLanguages: [languages_1.Language.it_IT],
    publicPages: [],
    enabledPages: [
        Object.assign({ route: "/your-questions", txPageName: "yourQuestions", groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ] }, lock_1.defaultPageLock),
        // {
        //   txPageName: "report",
        //   route: FrontendPageEnum.REPORT,
        //   groupsWhichCanSeeThePage: [
        //     SupportedGroupsEnum.USER,
        //     SupportedGroupsEnum.ADMIN,
        //   ],
        //   ...createPageLock([
        //     {
        //       reasonWhyLockedIframeUrl: "pageLock.iframeUrls.report",
        //       lockType: PageLockType.CHAT_COMPLETENESS,
        //     },
        //   ]),
        // },
        // {
        //   route: "/analysis",
        //   txPageName: "stats",
        //   groupsWhichCanSeeThePage: [
        //     SupportedGroupsEnum.USER,
        //     SupportedGroupsEnum.ADMIN,
        //   ],
        //   ...createPageLock([
        //     {
        //       reasonWhyLockedIframeUrl: "pageLock.iframeUrls.dashboard",
        //       lockType: PageLockType.CHAT_COMPLETENESS,
        //     },
        //   ]),
        // },
    ],
    showCallSupportButton: {
        telephoneNumberTx: "disease.chronic-urticaria.settings.supportNumber",
        helpPageUrl: "disease.chronic-urticaria.settings.surpportUrl",
    },
    authLogo: "/resources/mama-logo-wide.svg",
    headerLogo: "/resources/mama-logo-wide.svg",
    narrowHeaderLogo: "/resources/mama-logo.svg",
    favIcon: "/resources/mama-favicon.png",
    favIconHighres: "/resources/mama-favicon-highres.png",
    tenantNameTx: "disease.chronic-urticaria.settings.name",
    autoConfirmUsers: false,
    groupSecretsArns: {
        [deployment_environments_1.DeploymentEnvironment.DEV]: "arn:aws:secretsmanager:eu-central-1:616427543840:secret:group-secrets-chronic-spontaneous-urticaria-YqPezN",
        [deployment_environments_1.DeploymentEnvironment.PROD]: "arn:aws:secretsmanager:eu-central-1:025928572003:secret:group-secrets-chronic-spontaneous-urticaria-xesU5E",
    },
    identityProviders: [cognito_1.IdpEnum.FACEBOOK],
    metaDescriptionTx: "disease.chronic-urticaria.settings.description",
    dataGuards: {
        signUpAdmin: [legal_data_guard_1.legalDataGuard],
        existingAdmin: [updated_legal_policy_data_guard_1.updatedLegalPolicyDataGuard],
        signUpUser: [],
        existingUser: [
            updated_legal_policy_data_guard_1.updatedLegalPolicyDataGuard,
            (0, social_demographics_data_guard_1.socialDemographicsDataGuard)([
                { type: form_data_guard_1.FormDataCollectionInputType.NAME },
                { type: form_data_guard_1.FormDataCollectionInputType.EMAIL },
            ]),
        ],
    },
    userFeedback: [...user_feedbacks_1.defaultUserFeedbacks, ...user_feedbacks_1.followUpUserFeedbacks],
    profileInputs: profile_inputs_1.baseFormDataInputs,
};
